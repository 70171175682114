import {
  Button,
  Checkbox,
  Chip,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { Close } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import { HiPlus } from 'react-icons/hi';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  padding-top: 20px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const ChipsContainer = styled.div`
  width: 100%;
  border-top: 1px solid #e5e5e5;
  margin-top: 20px;
  padding-top: 20px;

  .chips-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
  }
`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  padding: 20px;

  @media screen and (max-width: 600px) {
    padding: 0;
    align-items: center;
    justify-content: center;
  }

  .carrier-selection-modal {
    position: relative;
    background-color: #fff;
    max-width: 700px;
    border-radius: 16px;
    padding: 20px;
    margin: 20px;
    width: 100%;
    text-align: left;
    max-height: 90vh;
    overflow-y: auto;

    @media screen and (max-width: 600px) {
      margin: 10px;
      padding: 15px;
      max-height: 95vh;
      width: calc(100% - 20px);
    }

    .toggle-wrapper {
      padding-top: 20px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      color: #515151;
      margin-bottom: 20px;

      .toggle {
        padding: 8px 16px;
        font-size: 14px;
        border: 1px solid #e5e5e5;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.2s;

        &:hover {
          background: #e5e5e5;
        }

        &.selected {
          background: #222c69;
          color: white;
          border-color: #222c69;
        }

        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
          &:hover {
            background: transparent;
          }
        }
      }
    }
  }
`;

function CarrierPreferrencePopup({
  selectedOptions = [],
  setSelectedOptions,
  showError,
  carrierName,
  options,
  limit,
}) {
  const [noneOptionSelected, setNoneOptionSelected] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [otherCarrierInput, setOtherCarrierInput] = useState('');
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [customCarriers, setCustomCarriers] = useState([]);

  useEffect(() => {
    if (selectedOptions && selectedOptions.includes('No Preferred Carrier')) {
      setNoneOptionSelected(true);
    } else {
      setNoneOptionSelected(false);
    }
  }, [selectedOptions]);

  const handleCarriersChange = (newSelectedCarriers) => {
    if (limit) {
      if (newSelectedCarriers.length <= limit)
        setSelectedOptions(newSelectedCarriers);
    } else {
      setSelectedOptions(newSelectedCarriers);
    }
  };

  const handleToggleClick = (option) => {
    if (option === 'Other') {
      setShowOtherInput(!showOtherInput);
      if (showOtherInput) {
        // Remove all custom carriers when Other is deselected
        const filteredCarriers = selectedOptions.filter(
          (carrier) => !customCarriers.includes(carrier)
        );
        handleCarriersChange(filteredCarriers);
        setCustomCarriers([]);
      }
      return;
    }

    if (selectedOptions.includes(option)) {
      handleCarriersChange(selectedOptions.filter((item) => item !== option));
    } else if (selectedOptions.length < limit) {
      handleCarriersChange([...selectedOptions, option]);
    }
  };

  const handleDeleteCarrier = (carrierToDelete) => {
    handleCarriersChange(
      selectedOptions.filter((carrier) => carrier !== carrierToDelete)
    );
    if (customCarriers.includes(carrierToDelete)) {
      setCustomCarriers(
        customCarriers.filter((carrier) => carrier !== carrierToDelete)
      );
    }
  };

  const handleOtherCarrierAdd = (e) => {
    if (
      e.key === 'Enter' &&
      otherCarrierInput.trim() &&
      selectedOptions.length < limit
    ) {
      const newCarrier = otherCarrierInput.trim();
      // Check if carrier already exists
      if (!selectedOptions.includes(newCarrier)) {
        handleCarriersChange([...selectedOptions, newCarrier]);
        setCustomCarriers([...customCarriers, newCarrier]);
      }
      setOtherCarrierInput('');
    }
  };

  return (
    <FormControl
      sx={{
        width: '100%',
      }}
    >
      {selectedOptions && Array.isArray(selectedOptions) && (
        <Container>
          <Button
            color='primary'
            variant='contained'
            startIcon={<HiPlus />}
            sx={{ borderRadius: '100px', textTransform: 'initial' }}
            disabled={noneOptionSelected}
            onClick={() => {
              setModalOpen(true);
            }}
          >
            Add Preferred Carriers
          </Button>
          <span>Or</span>
          <span>
            <Checkbox
              onChange={(e) => {
                if (e.target.checked) {
                  handleCarriersChange(['No Preferred Carrier']);
                  setNoneOptionSelected(true);
                } else {
                  handleCarriersChange([]);
                  setNoneOptionSelected(false);
                }
              }}
              checked={noneOptionSelected}
            />
            No Preferred Carrier
          </span>
          {selectedOptions.length > 0 &&
            selectedOptions[0] !== 'No Preferred Carrier' && (
              <ChipsContainer>
                <Typography variant='subtitle2' color='textSecondary'>
                  Added Carriers
                </Typography>
                <div className='chips-wrapper'>
                  {selectedOptions.map((carrier) => (
                    <Chip
                      key={carrier}
                      label={carrier}
                      onDelete={() => handleDeleteCarrier(carrier)}
                      color='primary'
                      variant='outlined'
                    />
                  ))}
                </div>
              </ChipsContainer>
            )}
        </Container>
      )}
      {modalOpen && (
        <ModalWrapper>
          <div className='carrier-selection-modal'>
            <IconButton
              onClick={() => {
                setModalOpen(false);
                setShowOtherInput(false);
              }}
              style={{ position: 'absolute', right: '10px', top: '10px' }}
            >
              <Close />
            </IconButton>
            <Typography
              variant='h4'
              style={{ fontWeight: '600', color: '#515151', fontSize: '16px' }}
            >
              Select up to {limit} preferred carriers
            </Typography>
            <div className='toggle-wrapper'>
              {options
                .filter((option) => option !== 'No Preferred Carrier')
                .map((option) => (
                  <span
                    className={`toggle ${
                      selectedOptions.includes(option) ||
                      (option === 'Other' && showOtherInput)
                        ? 'selected'
                        : ''
                    } ${
                      !selectedOptions.includes(option) &&
                      selectedOptions.length >= limit
                        ? 'disabled'
                        : ''
                    }`}
                    key={option}
                    onClick={() => handleToggleClick(option)}
                  >
                    {option}
                  </span>
                ))}
            </div>
            {showOtherInput && (
              <>
                <TextField
                  fullWidth
                  value={otherCarrierInput}
                  onChange={(e) => setOtherCarrierInput(e.target.value)}
                  onKeyDown={handleOtherCarrierAdd}
                  placeholder={`Add other carriers (${selectedOptions.length}/${limit})`}
                  disabled={selectedOptions.length >= limit}
                  helperText={`Press Enter to add. Maximum ${limit} other carriers allowed.`}
                  sx={{ marginBottom: 2 }}
                />
                {customCarriers.length > 0 && (
                  <div style={{ marginBottom: '20px' }}>
                    <Typography
                      variant='subtitle2'
                      color='textSecondary'
                      sx={{ marginBottom: '10px' }}
                    >
                      Other carriers Added
                    </Typography>
                    <div
                      style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}
                    >
                      {customCarriers.map((carrier) => (
                        <Chip
                          key={carrier}
                          label={carrier}
                          onDelete={() => handleDeleteCarrier(carrier)}
                          color='primary'
                          variant='outlined'
                        />
                      ))}
                    </div>
                  </div>
                )}
              </>
            )}
            {selectedOptions.length > 0 && (
              <Button
                variant='contained'
                fullWidth
                onClick={() => {
                  setModalOpen(false);
                  setShowOtherInput(false);
                }}
              >
                Continue
              </Button>
            )}
          </div>
        </ModalWrapper>
      )}
      {showError && (
        <span style={{ color: 'red', paddingTop: '5px', textAlign: 'left' }}>
          {' '}
          Required Field
        </span>
      )}
    </FormControl>
  );
}
export default CarrierPreferrencePopup;
