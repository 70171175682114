import React, { useEffect } from 'react';
import {
  fetchIsUATFromUrl,
  fetchUserNameFromUrl,
  getQueryParamsAsKeyValuePairs,
} from '../../hooks/queryParams';

import FormService from '../../services/FormService';
import Question from '../questionPage/Question';
import { Typography } from '@mui/material';
import convertDateString from '../questionPage/convertDateString';
import getLeadId from '../../hooks/getLeadIdFromUrl';
import { myFormDataStore } from '../../stores/FormDataStore';
import { observer } from 'mobx-react-lite';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`;

const PageContainer = styled.div`
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

function QuestionPage({ routeToQuotingTool }) {
  const qp = getQueryParamsAsKeyValuePairs();
  const [isLoading, setIsLoading] = React.useState(false);

  // behaviour can be smooth or auto
  const scrollToTopSection = (behaviour) => {
    window.scrollTo({ top: 0, behavior: behaviour, block: 'start' });
  };
  useEffect(() => {
    scrollToTopSection('auto');
  }, []);
  useEffect(() => {
    myFormDataStore.isSkip && scrollToTopSection('auto');
  }, [myFormDataStore.isSkip]);

  useEffect(() => {
    scrollToTopSection('smooth');
  }, [myFormDataStore.getCurrentStep()]);

  const updateSalesForceLead = async (additionalData = {}) => {
    if (myFormDataStore.getM101ObjectName() === '') {
      const persistedM101Object = localStorage.getItem(getLeadId());
      if (persistedM101Object !== null) {
        myFormDataStore.setM101ObjectName(persistedM101Object);
      }
    }
    const answers = myFormDataStore
      .getCurrentScreen()
      .questions.map((question) => question.answer);
    myFormDataStore.toggleQuestionsVisibility(answers);

    const data = {
      leadId: getLeadId(),
      userName: fetchUserNameFromUrl(),
      m101Name: myFormDataStore.getM101ObjectName(),
      Carrier_Preference__c: myFormDataStore.getCarrierPreference().join(';'),
      Medicare_Part_B_Date_Current__c: convertDateString(
        myFormDataStore.getMedicarePlanBCurrentDate()
      ),
      medicare101VideoProgress: myFormDataStore.getProgressSummary(),
      currentCoverageSelf: myFormDataStore.getInsuranceType(),
      currentPlanTypeSelf: myFormDataStore.getPlanType(),
      currentProviderSelf: myFormDataStore.getProvider(),
      m101ConfirmedCoverage: myFormDataStore.getConfirmedCoveragePreference()
        ? myFormDataStore.getConfirmedCoveragePreference()
        : '',
      m101FirstCoverage: myFormDataStore.getFirstCoveragePreference()
        ? myFormDataStore.getFirstCoveragePreference()
        : '',
      callbackStatus: qp.callbackStatus ? qp.callbackStatus : '',
      forUAT: fetchIsUATFromUrl(),
      ...additionalData,
    };
    if (qp.spouse === 'yes') {
      data['currentCoverageSpouse'] = myFormDataStore.getSpouseInsuranceType();
      data['currentPlanTypeSpouse'] = myFormDataStore.getSpousePlanType();
      data['currentProviderSpouse'] = myFormDataStore.getSpouseProvider();
      data['Medicare_Part_B_Date_Current__c_spouse'] = convertDateString(
        myFormDataStore.getSpouseMedicarePlanBCurrentDate()
      );
    }

    if (
      myFormDataStore.getM101ObjectName() === null ||
      myFormDataStore.getM101ObjectName() === undefined ||
      myFormDataStore.getM101ObjectName() === ''
    ) {
      const result = await FormService.updateQTSalesforceLead(data);
      myFormDataStore.setM101ObjectName(result.data.data.M101ObjectName);
    } else {
      FormService.updateQTSalesforceLead(data)
        .then((data) => {
          console.log('Salesforce lead updated!');
        })
        .catch((error) => {
          console.log('error while updating SF lead: ', error);
        });
    }
  };

  return (
    <Wrapper>
      <PageContainer id='container'>
        <Typography
          color='primary'
          sx={{
            display: { xs: 'none', sm: 'block' },
            textAlign: 'center',
            width: '100%',
            fontSize: '18px',
            fontWeight: 600,
          }}
        >
          {myFormDataStore.getCurrentStep().label}
        </Typography>

        <Question
          routeToQuotingTool={routeToQuotingTool}
          handleScrollToTopSection={scrollToTopSection}
          updateSalesForceLead={updateSalesForceLead}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
      </PageContainer>
    </Wrapper>
  );
}

export default observer(QuestionPage);
