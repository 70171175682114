import React from 'react';
import { Typography } from '@mui/material';
import { myFormDataStore } from '../../stores/FormDataStore.tsx';
import { observer } from 'mobx-react-lite';
import styled from '@emotion/styled';

const Container = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
  display: none;
  padding: 20px 10px;
  @media screen and (max-width: 600px) {
    display: flex;
  }
`;

function MobileNav() {
  return (
    <Container>
      <Typography
        color='primary'
        sx={{
          textAlign: 'center',
          width: '100%',
          fontSize: '16px',
          fontWeight: 600,
        }}
      >
        {myFormDataStore.getCurrentStep().label}
      </Typography>
    </Container>
  );
}

export default observer(MobileNav);
