import { FaAngleDoubleDown, FaAngleDoubleUp } from 'react-icons/fa';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

import React from 'react';
import { Typography } from '@mui/material';
import getThemeName from '../../hooks/useThemeFromUrl';
import { myFormDataStore } from '../../stores/FormDataStore.tsx';
import { observer } from 'mobx-react-lite';
import styled from '@emotion/styled';
import { styled as styledMui } from '@mui/material/styles';
import themes from '../../themes';

const brand = getThemeName();

const MyLinearProgress = styledMui(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  width: '100%',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary,
  },
}));

const handleCollapseAll = () => {
  myFormDataStore.collapseAll();
};

const handleOpenAll = () => {
  myFormDataStore.openAll();
};

const Container = styled.div`
  padding: 30px 0px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 10px;
  border-bottom: 1px solid #e5e5e5;
  @media screen and (max-width: 600px) {
    padding: 20px;
    margin-bottom: 0px;
  }
`;

const CollapseButton = styled.div`
  float: right;
  padding-left: 5px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;
  display: flex;
  cursor: pointer;
  background-color: ${themes(brand).palette.primary.main};
  color: white;
  border-radius: 9999px;
`;

const CollapseButtonContainer = styled.div`
  width: auto;
  @media screen and (max-width: 600px) {
    display: none;
  }
`;

function ProgressIndicator() {
  function toFixed(num, fixed) {
    var re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(re)[0];
  }

  return (
    <Container>
      <Typography
        color='primary'
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          fontWeight: '600',
          fontSize: '16px',
        }}
      >
        <span>Progress</span>
        <span style={{ fontSize: '12px', fontWeight: 400, color: '#515151' }}>
          {Math.round(myFormDataStore.getFormProgress(), 2)}% Completed
        </span>
      </Typography>
      <MyLinearProgress
        variant='determinate'
        value={myFormDataStore.getFormProgress()}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        {/* <Typography
          fontSize={themes(brand).typography.myFontSizes.h6}
          style={{ float: 'left' }}
        >
          {' '}
          {Math.round(myFormDataStore.getFormProgress(), 2)}% Complete
        </Typography> */}
        {/* <CollapseButtonContainer>
          {myFormDataStore.checkIsOpen() ? (
            <CollapseButton onClick={() => handleCollapseAll()}>
              <FaAngleDoubleUp style={{ color: 'white', fontSize: '14px' }} />
            </CollapseButton>
          ) : (
            <CollapseButton onClick={() => handleOpenAll()}>
              <FaAngleDoubleDown style={{ color: 'white', fontSize: '14px' }} />
            </CollapseButton>
          )}
        </CollapseButtonContainer> */}
      </div>
    </Container>
  );
}

export default observer(ProgressIndicator);
