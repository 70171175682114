import React from 'react';
import { Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import styled from '@emotion/styled';

const Container = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #666;
  text-align: left;
`;

function createMarkup(str) {
  return {
    __html: str,
  };
}

function Transcript({ text }) {
  return (
    <Container>
      <Typography
        textAlign='left'
        variant='h3'
        fontWeight={600}
        color='primary'
        sx={{
          borderBottom: '1px solid #EFEFEF',
          paddingBottom: '10px',
          marginBottom: '20px',
        }}
      >
        <span
          style={{ borderBottom: '2px solid #222C69', paddingBottom: '10px' }}
        >
          Transcript
        </span>
      </Typography>
      <p>{<div dangerouslySetInnerHTML={createMarkup(text)} />}</p>
    </Container>
  );
}

export default observer(Transcript);
