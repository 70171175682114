import { CircularProgress, Typography } from '@mui/material';
import React, { useEffect } from 'react';

import ArrowButton from '../common/Buttons/ArrowButton';
import CarrierPreferrencePopup from '../answerComponents/CarrierPreferrencePopup';
import ChipsAnswer from '../answerComponents/ChipsAnswer';
import DateAnswer from '../answerComponents/DateAnswer';
import DropDownAnswer from '../answerComponents/DropdownAnswer';
import ErrorBoundary from '../common/ErrorBoundary';
import McqAnswer from '../answerComponents/McqAnswer';
import { MdClose } from 'react-icons/md';
import Modal from 'react-modal';
import MultiPickDropDownAnswer from '../answerComponents/MultiPickDropdownAnswer';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PhoneIcon from '../../icons/phone_icon.svg';
import { QuestionType } from '../../data/enums';
import Transcript from './Transcript';
import VideoSection from './VideoSection';
import { fireEvent } from '../../cra';
import { getContact } from '../../hooks/getContact';
import { getQueryParamsAsKeyValuePairs } from '../../hooks/queryParams';
import getThemeName from '../../hooks/useThemeFromUrl';
import { myFormDataStore } from '../../stores/FormDataStore';
import { observer } from 'mobx-react-lite';
import styled from '@emotion/styled';
import themes from '../../themes';

const brand = getThemeName();

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '16px',
    border: '0px',
    background: '#fff',
  },
  overlay: {
    background: 'rgba(0,0,0,0.85)',
    zIndex: '2000',
  },
};

const Container = styled.div`
  border: 1px solid #222c69;
  border-radius: 16px;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 900px;
  min-height: 100vh;
  margin: 0px auto;
  padding: 40px 20px;
  row-gap: 40px;
  text-align: left;

  @media screen and (max-width: 600px) {
    text-align: center;
  }
`;

const ButtonsContainer = styled.div`
  border-top: 1px solid #e5e5e5;
  position: sticky;
  bottom: 0px;
  background-color: #fff;
  z-index: 10;
`;

const ButtonsWrapper = styled.div`
  max-width: 900px;
  margin: 0px auto;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;

  .next-back-wrapper {
    display: flex;
    gap: 10px;
  }

  @media screen and (max-width: 400px) {
    padding: 10px;
    .next-back-wrapper {
      gap: 5px;
    }
  }
`;

const PhoneIconContainer = styled.div`
  height: 15px;
`;

const CallSection = styled.div`
  display: none;
  @media screen and (max-width: 600px) {
    display: block;
  }
`;

const ToolbarCallDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
`;

const AnswerDiv = styled.div`
  flex: 1;
  width: 100%;
`;

const PopupDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  row-gap: 10px;
  position: relative;
`;

const PopupButtonsDiv = styled.div`
  display: flex;
  column-gap: 20px;

  @media screen and (max-width: 390px) {
    flex-direction: column;
  }
`;

const CloseIconDiv = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
`;

function Question({
  handleScrollToTopSection,
  routeToQuotingTool,
  updateSalesForceLead,
  setIsLoading,
  isLoading,
}) {
  const params = new URLSearchParams(window.location.search);
  const brandName = params.has('leadBrand') ? params.get('leadBrand') : '';
  const [answers, setAnswers] = React.useState(
    myFormDataStore.getCurrentScreen().questions &&
      myFormDataStore.getCurrentScreen().questions.length > 0
      ? myFormDataStore
          .getCurrentScreen()
          .questions.map((question) => question.answer)
      : []
  );

  const [showValidationErrors, setShowValidationErrors] = React.useState(
    myFormDataStore.getCurrentScreen().questions &&
      myFormDataStore.getCurrentScreen().questions.length > 0
      ? false
      : []
  );

  const [chipTexts, setChipTexts] = React.useState(
    myFormDataStore.getCurrentScreen().questions &&
      myFormDataStore.getCurrentScreen().questions.length > 0
      ? myFormDataStore.getCurrentScreen().questions.map((question) => '')
      : []
  );

  const [isInvalid, setIsInvalid] = React.useState(false);

  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const isThankYouPage =
    myFormDataStore.getCurrentStep().stepId === '3.75' ? true : false;

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    myFormDataStore.getCurrentStep().visited = true;
    myFormDataStore.getCurrentSection().visited = true;
  }, [
    myFormDataStore.getCurrentStepIndex(),
    myFormDataStore.getCurrentSectionIndex(),
  ]);
  useEffect(() => {
    setAnswers(
      myFormDataStore.getCurrentScreen().questions &&
        myFormDataStore.getCurrentScreen().questions.length > 0
        ? myFormDataStore
            .getCurrentScreen()
            .questions.map((question) => question.answer)
        : []
    );
    setShowValidationErrors(
      myFormDataStore.getCurrentScreen().questions &&
        myFormDataStore.getCurrentScreen().questions.length > 0
        ? myFormDataStore.getCurrentScreen().questions.map((question) => false)
        : []
    );

    myFormDataStore.setVisitedStep(
      myFormDataStore.getCurrentSectionIndex(),
      myFormDataStore.getCurrentStepIndex(),
      true
    );
    myFormDataStore.setVisitedSection(
      myFormDataStore.getCurrentSectionIndex(),
      true
    );

    fireEvent(
      'pageLoad',
      {},
      {
        leadAppStage: 'Medicare 101',
        leadAppSubStage: myFormDataStore.getLeadAppSubStage(),
        description: 'M101 Question Changed',
      }
    );

    if (myFormDataStore.getCurrentScreen().id === '3.75.1') {
      setIsLoading(true);
      myFormDataStore.updateFormProgressForThankYouPage();
      updateSalesForceLead()
        .then((data) => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });

      fireEvent('formSubmit', null, {
        progress: myFormDataStore.getFormProgessText(),
      });

      const timer = setTimeout(() => {
        fireEvent('saveLeadDetail', {}, {});
        updateSalesForceLead({
          leadAppStage: 'Medicare 101',
          callbackLogic: myFormDataStore.isThankYouPage()
            ? 'Completed M101 to Quotes'
            : 'Skipped M101 to Quotes',
          leadAppSubstage:
            myFormDataStore.getCurrentScreen().id +
            ' - ' +
            myFormDataStore.getCurrentStep().label,
        });
        routeToQuotingTool();
      }, 30000);
      return () => clearTimeout(timer);
    }
  }, [
    myFormDataStore.getCurrentScreenIndex(),
    myFormDataStore.getCurrentStepIndex(),
    myFormDataStore.getCurrentSectionIndex(),
  ]);

  const qp = getQueryParamsAsKeyValuePairs();

  useEffect(() => {
    qp.spouse === 'yes' &&
      myFormDataStore.makeSpouseSupplementalQuestionsVisible();
  }, []);

  function createMarkup(str) {
    return {
      __html: str,
    };
  }

  useEffect(() => {
    const changedAnswerIndices =
      myFormDataStore.toggleQuestionsVisibility(answers);
    changedAnswerIndices &&
      changedAnswerIndices.forEach((index) => {
        updateAnswer(index, '');
      });
  }, [answers]);

  function updateShowValidationError(index, newValue) {
    const newArray = [...showValidationErrors];
    newArray[index] = newValue;
    setShowValidationErrors(newArray);
  }

  function updateAnswer(index, newValue) {
    if (newValue.includes('No Preferred Carrier')) {
      const newArray = [...answers];
      newArray[index] = ['No Preferred Carrier'];
      setAnswers(newArray);
    } else {
      const newArray = [...answers];
      newArray[index] = newValue;
      setAnswers(newArray);
    }
  }
  function updateChipText(index, newValue) {
    const newArray = [...answers];
    newArray[index] = newValue;
    setChipTexts(newArray);
  }

  function isAnswerValid(answer, questionType) {
    // Check if string is empty or contains only spaces
    if (!answer || /^\s*$/.test(answer)) return false;

    if (Array.isArray(answer)) {
      // If answer is an array, check if every element is valid as per the questionType
      return answer.every((item) => isAnswerValid(item, questionType));
    }

    const dateRegex = /^[a-zA-Z]+-\d{4}$/; // regex to match <month>-<year> format
    const dropDownRegex = /^(?:\S+\s*)+\s*$/;
    const chipsRegex = /^(?=.*[a-zA-Z])[a-zA-Z,\s()-]*$/; // Check if string contains only a-z, A-Z, commas and spaces
    let regex = /^(?:\w+\s*)+\s*$/; // regex to match words with spaces
    if (questionType === QuestionType.Date) regex = dateRegex;
    if (questionType === QuestionType.DropdownBox) regex = dropDownRegex;
    if (questionType === QuestionType.CheckBox) regex = dropDownRegex;
    if (
      questionType === QuestionType.Chips ||
      questionType === QuestionType.MultiDropdownBox
    )
      regex = chipsRegex;
    return regex.test(answer);
  }

  function arraysMatch(array1, array2) {
    // Check if the arrays have the same length
    if (array1.length !== array2.length) {
      return false;
    }

    // Compare each element of the arrays
    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }

    // All elements match
    return true;
  }

  const handleSubmit = () => {
    let isFormValid = true;
    setIsInvalid(false);
    let updatedAnswers = [...answers]; // create a copy of answers to update
    if (
      myFormDataStore.getCurrentScreen().questions &&
      myFormDataStore.getCurrentScreen().questions.length > 0
    ) {
      const validationErrors = [];
      for (let index in answers) {
        let isAnswerValidBool = myFormDataStore.getCurrentScreen().questions[
          index
        ].isVisible
          ? isAnswerValid(
              answers[index],
              myFormDataStore.getCurrentScreen().questions[index].questionType
            )
          : true;

        if (
          myFormDataStore.getCurrentScreen().questions[index].questionType ===
          QuestionType.Chips
        ) {
          // check if valid chip text exists
          if (chipTexts.length !== 0 && chipTexts[2] !== '') {
            if (isAnswerValid(chipTexts[index], QuestionType.Chips)) {
              updatedAnswers[index] = [...answers[index], chipTexts[index]]; // update the answer
              updateChipText(index, ''); // clear the chip text
              isAnswerValidBool = true;
            } else {
              isAnswerValidBool = false;
              setIsInvalid(true);
            }
          }
        }
        validationErrors.push(!isAnswerValidBool);
        isFormValid = isFormValid && isAnswerValidBool;
      }
      setShowValidationErrors(validationErrors);
      !arraysMatch(answers, updatedAnswers) && setAnswers(updatedAnswers);
    }
    if (isFormValid) {
      handleScrollToTopSection('smooth');
      myFormDataStore.newHandleSubmit(updatedAnswers); // call newHandleSubmit with the updated answers
    }
  };

  const skipHandlers = {
    video: () => {
      updateSalesForceLead({
        medicare101VideoProgress: myFormDataStore.getCurrentVideoProgress(),
      });
      handleScrollToTopSection('smooth');
      myFormDataStore.handleSkip();
    },
    toQuotes: () => {
      fireEvent('saveLeadDetail', {}, {});
      setTimeout(() => {
        updateSalesForceLead({
          leadAppStage: 'Medicare 101',
          callbackLogic: myFormDataStore.isThankYouPage()
            ? 'Completed M101 to Quotes'
            : 'Skipped M101 to Quotes',
          leadAppSubstage:
            myFormDataStore.getCurrentScreen().id +
            ' - ' +
            myFormDataStore.getCurrentStep().label,
        });
        routeToQuotingTool();
      }, 1000);
    },
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div>
      <Wrapper>
        {myFormDataStore.getCurrentStep().videoUrl && <VideoSection />}
        {!myFormDataStore.getCurrentStep().hideQuestionBox && (
          <>
            {!myFormDataStore.getCurrentStep().isInfo && (
              <Container>
                <Typography
                  sx={{
                    fontSize: '20px',
                    width: '100%',
                  }}
                  fontWeight={500}
                >
                  {myFormDataStore.getCurrentScreen().title ||
                    myFormDataStore.getCurrentStep().label}
                </Typography>

                {myFormDataStore
                  .getCurrentScreen()
                  .questions.map((question, index) => {
                    return question.isVisible ? (
                      <>
                        {question.title && (
                          <Typography
                            variant='h7'
                            color='greys.dark'
                            key={question.id}
                            sx={{
                              width: '100%',
                              paddingTop: '40px',
                            }}
                          >
                            <div
                              dangerouslySetInnerHTML={createMarkup(
                                question.title
                              )}
                            />
                          </Typography>
                        )}
                        <br />
                        <ErrorBoundary fallback={<p>Something went wrong</p>}>
                          <AnswerDiv>
                            {question.questionType ===
                              QuestionType.CheckBox && (
                              <McqAnswer
                                options={question.checkBox.options}
                                value={answers[index]}
                                handleChange={(newValue) => {
                                  updateAnswer(index, newValue);
                                  updateShowValidationError(index, false);
                                }}
                                showError={showValidationErrors[index]}
                                key={question.id}
                              />
                            )}
                            {question.questionType ===
                              QuestionType.DropdownBox &&
                              (!answers[index] ||
                                question.dropDown.options.includes(
                                  answers[index]
                                )) && (
                                <DropDownAnswer
                                  options={question.dropDown.options}
                                  key={question.id}
                                  label='Your Answer'
                                  value={answers[index]}
                                  handleChange={(value) => {
                                    updateAnswer(index, value);
                                    updateShowValidationError(index, false);
                                  }}
                                  color='primary'
                                  allowOtherValues={false}
                                  showValidationError={
                                    showValidationErrors[index]
                                  }
                                />
                              )}
                            {question.questionType === QuestionType.Date && (
                              <DateAnswer
                                value={answers[index]}
                                key={question.id}
                                handleChange={(date) => {
                                  updateAnswer(index, date);
                                }}
                                showValidationError={
                                  showValidationErrors[index]
                                }
                                setValidationErrorFalse={() => {
                                  updateShowValidationError(index, false);
                                }}
                              />
                            )}
                            {question.questionType === QuestionType.Chips &&
                              Array.isArray(answers[index]) && (
                                <ChipsAnswer
                                  chips={answers[index]}
                                  handleChipsChange={(chips) => {
                                    updateAnswer(index, chips);
                                    updateShowValidationError(index, false);
                                  }}
                                  // options={options}
                                  showError={showValidationErrors[index]}
                                  chipsText={chipTexts[index]}
                                  setChipsText={(newValue) => {
                                    updateChipText(index, newValue);
                                    updateShowValidationError(index, false);
                                  }}
                                  isInvalid={isInvalid}
                                  limit={question.limit}
                                />
                              )}
                            {question.questionType ===
                              QuestionType.MultiDropdownBox && (
                              <MultiPickDropDownAnswer
                                color='primary'
                                selectedOptions={answers[index]}
                                options={question.dropDown.options}
                                setSelectedOptions={(newCarriers) => {
                                  updateAnswer(index, newCarriers);
                                  updateShowValidationError(index, false);
                                }}
                                showError={showValidationErrors[index]}
                                limit={question.limit}
                              />
                            )}
                            {question.questionType ===
                              QuestionType.CarrierPreferrence && (
                              <CarrierPreferrencePopup
                                color='primary'
                                selectedOptions={answers[index]}
                                options={question.dropDown.options}
                                setSelectedOptions={(newCarriers) => {
                                  updateAnswer(index, newCarriers);
                                  updateShowValidationError(index, false);
                                }}
                                showError={showValidationErrors[index]}
                                limit={question.limit}
                              />
                            )}
                          </AnswerDiv>
                        </ErrorBoundary>
                      </>
                    ) : null;
                  })}
              </Container>
            )}
          </>
        )}
        {myFormDataStore.getCurrentStep().transcript && (
          <Transcript
            text={
              brandName === 'aetna-medicare'
                ? myFormDataStore.getCurrentStep().aetnaTranscript.text
                : brandName === 'mutual of omaha-medicare'
                ? myFormDataStore.getCurrentStep().mutualOfOmahaTranscript.text
                : myFormDataStore.getCurrentStep().transcript.text
            }
          />
        )}

        <CallSection>
          <Typography color='primary' fontWeight={500}>
            Speak with a Licensed Insurance Agent
          </Typography>
          <a
            href={getContact(getThemeName()).href}
            style={{ textDecoration: 'none' }}
          >
            <ToolbarCallDiv>
              <PhoneIconContainer>
                <img style={{ height: '100%' }} src={PhoneIcon} />
              </PhoneIconContainer>
              <Typography color='secondary' fontWeight='bold'>
                {getContact(getThemeName()).text}
              </Typography>
            </ToolbarCallDiv>
          </a>
        </CallSection>
      </Wrapper>
      <ButtonsContainer>
        <ButtonsWrapper>
          <ArrowButton
            variant='text'
            color='primary'
            sx={{
              textTransform: 'unset !important',
              textDecoration: 'underline',
            }}
            onClick={() => {
              if (isThankYouPage) {
                skipHandlers.toQuotes();
              } else {
                openModal();
              }
            }}
          >
            {isLoading ? (
              <CircularProgress color='inherit' disableShrink size={20} />
            ) : (
              <>
                <Typography
                  fontWeight='bold'
                  sx={{
                    fontSize: '14px',
                    fontWeight: '400',
                  }}
                  id={`skip-to-quotes-arrow-button-${myFormDataStore.getButtonId()}`}
                >
                  {isThankYouPage ? 'Continue to Quotes' : 'Skip to Quotes'}
                </Typography>
                <NavigateNextIcon
                  id={`skip-to-quotes-arrow-button-${myFormDataStore.getButtonId()}`}
                  sx={isThankYouPage && { fontSize: '32px' }}
                />
              </>
            )}
          </ArrowButton>
          <div className='next-back-wrapper'>
            {myFormDataStore.getCurrentScreen().id !== '1.05.1' && (
              <ArrowButton
                sx={{
                  width: '100%',
                  maxWidth: '200px',
                  textTransform: 'unset !important',
                }}
                variant='outlined'
                onClick={() => {
                  const indices = myFormDataStore.previousScreen();
                  indices[0] !== myFormDataStore.getCurrentSectionIndex() &&
                    myFormDataStore.toggleAccordionSection(indices[0]);
                  myFormDataStore.updateIndices(
                    indices[0],
                    indices[1],
                    indices[2]
                  );
                }}
              >
                <NavigateBeforeIcon
                  id={`${(myFormDataStore.getCurrentSectionIndex() !==
                    myFormDataStore.previousScreen()[0] ||
                  myFormDataStore.getCurrentStepIndex() !==
                    myFormDataStore.previousScreen()[1]
                    ? 'Previous Section'
                    : 'Previous Question'
                  )
                    .replace(/\s/g, '-')
                    .toLowerCase()}-arrow-button-${myFormDataStore.getButtonId()}`}
                />
                <Typography
                  fontWeight='bold'
                  variant='h7'
                  id={`${(myFormDataStore.getCurrentSectionIndex() !==
                    myFormDataStore.previousScreen()[0] ||
                  myFormDataStore.getCurrentStepIndex() !==
                    myFormDataStore.previousScreen()[1]
                    ? 'Previous Section'
                    : 'Previous Question'
                  )
                    .replace(/\s/g, '-')
                    .toLowerCase()}-arrow-button-${myFormDataStore.getButtonId()}`}
                >
                  Back
                </Typography>
              </ArrowButton>
            )}
            {!myFormDataStore.isThankYouPage() && (
              <ArrowButton
                sx={{
                  width: '100%',
                  maxWidth: '200px',
                  textTransform: 'unset !important',
                }}
                variant='contained'
                onClick={() => {
                  if (!myFormDataStore.isFirstQuestionGettingSaved())
                    handleSubmit();
                }}
              >
                {myFormDataStore.isFirstQuestionGettingSaved() ? (
                  <CircularProgress color='inherit' disableShrink size={20} />
                ) : (
                  <>
                    <Typography
                      fontWeight='bold'
                      variant='h7'
                      id={`${(myFormDataStore.isThankYouPage()
                        ? 'Submit'
                        : myFormDataStore.getCurrentScreen().questions
                            .length === 0
                        ? 'Next Section'
                        : myFormDataStore.getCurrentScreenIndex() <
                          myFormDataStore
                            .getCurrentStep()
                            .screens.filter((s) => s.isVisible).length -
                            1
                        ? 'Next Question'
                        : 'Next Section'
                      )
                        .replace(/\s/g, '-')
                        .toLowerCase()}-arrow-button-${myFormDataStore.getButtonId()}`}
                    >
                      {myFormDataStore.isThankYouPage() ? 'Submit' : 'Next'}
                    </Typography>
                    <NavigateNextIcon
                      id={`${(myFormDataStore.isThankYouPage()
                        ? 'Submit'
                        : myFormDataStore.getCurrentScreen().questions
                            .length === 0
                        ? 'Next Section'
                        : myFormDataStore.getCurrentScreenIndex() <
                          myFormDataStore
                            .getCurrentStep()
                            .screens.filter((s) => s.isVisible).length -
                            1
                        ? 'Next Question'
                        : 'Next Section'
                      )
                        .replace(/\s/g, '-')
                        .toLowerCase()}-arrow-button-${myFormDataStore.getButtonId()}`}
                    />
                  </>
                )}
              </ArrowButton>
            )}
          </div>
        </ButtonsWrapper>
      </ButtonsContainer>
      {/* Skip to Quotes Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
      >
        <PopupDiv>
          <CloseIconDiv onClick={() => closeModal()}>
            <MdClose />
          </CloseIconDiv>
          <Typography
            fontSize={themes(brand).typography.myFontSizes.h3}
            fontWeight='bold'
          >
            Are you ready to view your Medicare plan options?
          </Typography>
          <Typography fontSize={themes(brand).typography.myFontSizes.h4}>
            Reading through this section will help you understand Medicare
            better
          </Typography>
          <PopupButtonsDiv>
            <ArrowButton
              variant='contained'
              color='secondary'
              sx={{
                textTransform: 'unset !important',
                '@media (max-width: 390px)': {
                  marginBottom: '15px',
                },
              }}
              onClick={() => closeModal()}
            >
              <Typography fontSize={themes(brand).typography.myFontSizes.h6}>
                No, Stay Here
              </Typography>
            </ArrowButton>

            <ArrowButton
              variant='outlined'
              sx={{ textTransform: 'unset !important' }}
              onClick={() => {
                skipHandlers.toQuotes();
              }}
            >
              <Typography fontSize={themes(brand).typography.myFontSizes.h6}>
                Yes, Show Me My Plans
              </Typography>
            </ArrowButton>
          </PopupButtonsDiv>
        </PopupDiv>
      </Modal>
    </div>
  );
}

export default observer(Question);
