import React, { useEffect } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { myFormDataStore } from '../../stores/FormDataStore.tsx';
import styled from '@emotion/styled';
import { styled as styledMui } from '@mui/material/styles';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const years = [];

const startYear = new Date().getFullYear() + 1;
const currentYear = 1950;

for (let i = startYear; i > currentYear - 1; i--) {
  years.push(i);
}

const StyledSelect = styledMui(Select)`
border-radius: 100px;
`;

const Container = styled.div`
  display: flex;
  gap: 10px;
  max-width: 500px;
`;

function DateAnswer({
  handleChange,
  value,
  showValidationError,
  setValidationErrorFalse,
}) {
  const question = myFormDataStore.getCurrentQuestion();

  const [month, setMonth] = React.useState('');
  const [year, setYear] = React.useState('');
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [validations, setValidations] = React.useState([
    isValid(month),
    isValid(year),
  ]);

  function isValid(str) {
    return str !== '';
  }

  useEffect(() => {
    const data = question.answer.split('-');

    if (data.length === 2) {
      setMonth(data[0]);
      setYear(data[1]);
    }
  }, []);

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
    setValidationErrorFalse();
  };
  const handleyearChange = (event) => {
    setYear(event.target.value);
    setValidationErrorFalse();
  };

  useEffect(() => {
    handleChange(`${month}-${year}`);
    setValidations([isValid(month), isValid(year)]);
  }, [month, year]);

  return (
    <Container>
      <FormControl size='small' style={{ width: '100%', maxWidth: '500px' }}>
        <InputLabel
          id='demo-simple-select-label'
          sx={{
            color: showValidationError && !validations[0] ? 'red' : 'grey',
          }}
        >
          Month
        </InputLabel>
        <StyledSelect
          labelId='month-select-label'
          error={showValidationError && !validations[0]}
          id={myFormDataStore.getCurrentQuestion().title + '-month'}
          value={month}
          label='month'
          onChange={(event) => handleMonthChange(event)}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: '40%',
              },
            },
          }}
        >
          {months.map((m) => (
            <MenuItem key={m} value={m}>
              {m}
            </MenuItem>
          ))}
        </StyledSelect>
        {showValidationError && !validations[0] && (
          <span style={{ color: 'red', paddingTop: '5px' }}>
            {' '}
            Required Field
          </span>
        )}
      </FormControl>
      <FormControl size='small' style={{ width: '100%', maxWidth: '500px' }}>
        <InputLabel
          id='demo-simple-select-label'
          sx={{
            color: showValidationError && !validations[1] ? 'red' : 'grey',
          }}
        >
          Year
        </InputLabel>
        <StyledSelect
          error={showValidationError && !validations[1]}
          labelId='year-select-label'
          id={myFormDataStore.getCurrentQuestion().title + '-year'}
          value={year}
          label='year'
          onChange={(event) => handleyearChange(event)}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: '40%',
              },
            },
          }}
        >
          {years.map((y) => (
            <MenuItem key={y} sx={{ height: '100%' }} value={y}>
              {y}
            </MenuItem>
          ))}
        </StyledSelect>
        {showValidationError && !validations[1] && (
          <span style={{ color: 'red', paddingTop: '5px' }}>
            {' '}
            Required Field
          </span>
        )}
      </FormControl>
    </Container>
  );
}

export default DateAnswer;
